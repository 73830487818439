@import url(https://fonts.googleapis.com/earlyaccess/cwtexkai.css);

.App,
.App input,
.App button {
  font-size: 1.2rem !important;
  font-family: "serif", "DFKai-sb", "標楷體", "cwTeXKai";
}
.App .alert {
  font-size: 1.5rem !important;
}
.App .alert-success {
  color: #0b2211;
}

label:not(.form-check-label) {
  font-weight: 500;
}
a {
  color: #61dafb;
  text-decoration: none;
}

:root {
  --accent-dark: #ac6be9;
  --accent-light: #bb5bff;
}

.FormError {
  color: red;
  margin-top: 5px;
}

.disclaimer {
  color: var(--accent-light) !important;
  font-size: 1rem;
  font-weight: 500;
}
