@import url(https://fonts.googleapis.com/earlyaccess/cwtexkai.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url(../../static/media/background.83fa1432.png);
  background-position: top left;
  background-size: 100vw;
  background-repeat: no-repeat;
}

@media screen and (min-width: 768px) {
  /* two background images on larger screen*/
  /* body:not([role~="home"]) {} */
  body {
    background-image: url(../../static/media/title.dc70e006.png), url(../../static/media/background.83fa1432.png);
    background-position: 5vh 15vh, top left;
    background-size: 15vw, 100vw;
    background-repeat: no-repeat, no-repeat;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* responsive line break under the break point */
/* https://getbootstrap.com/docs/4.0/layout/grid/#grid-options */
@media screen and (min-width: 768px) {
  .rwd-break-md {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .rwd-break-lg {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .rwd-break-xl {
    display: none;
  }
}

.App,
.App input,
.App button {
  font-size: 1.2rem !important;
  font-family: "serif", "DFKai-sb", "標楷體", "cwTeXKai";
}
.App .alert {
  font-size: 1.5rem !important;
}
.App .alert-success {
  color: #0b2211;
}

label:not(.form-check-label) {
  font-weight: 500;
}
a {
  color: #61dafb;
  text-decoration: none;
}

:root {
  --accent-dark: #ac6be9;
  --accent-light: #bb5bff;
}

.FormError {
  color: red;
  margin-top: 5px;
}

.disclaimer {
  color: #bb5bff !important;
  color: var(--accent-light) !important;
  font-size: 1rem;
  font-weight: 500;
}

.DebugNav {
  background-color: #dddddd;
  margin-top: 10px;
}
.DebugNav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.DebugNav li {
  display: inline;
  margin: 0 1rem;
}
.DebugNav span {
  font: smaller;
}

.Footer {
  font-size: "smaller";
}

.ChoiceElement {
  display: block;
  text-align: center;
  cursor: pointer;
}

.ChoiceElement input[type="radio"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.ChoiceElement .card {
  display: block;
  padding: 8px 0 !important;
  margin-top: 10px !important;
  position: relative;
}
.ChoiceElement input[type="radio"] + .card {
  width: 100%;
  outline: 2px solid rgba(0, 0, 0, 0.15);
  box-shadow: 3px 3px 5px #ccc;
}
.ChoiceElement input[type="radio"]:hover + .card {
  outline: 3px solid var(--accent-light);
}
/* circle around photo */
/* .ChoiceElement input[type="radio"]:hover + .card .poster {
  border-radius: 50%;
  box-shadow: 0 0 0 0.5rem #000, 0 0 1rem inset;
} */
.ChoiceElement input[type="radio"]:checked + .card {
  outline: 7px solid var(--accent-dark);
  transition: all 0.1s ease;
}

.ChoiceElement .label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 500;
}

.ChoiceElement .poster {
  display: block;
  margin: 5px auto;
  max-width: 95%;
  width: auto;
  height: auto;
}

.ChoiceElement .radio {
  height: 2.5rem;
  margin-right: 0.3rem;
}

